@import '../../../../scss/theme-bootstrap';

.text-tout-full-width {
  @include container-padding-rules;
  margin-#{$ldirection}: auto;
  margin-#{$rdirection}: auto;
  // Typography defaults when none are selected in cms
  .content-block__line {
    &--eyebrow {
      .style--bare {
        @include text-body-text--tags;
      }
    }
    &--headline {
      .style--bare {
        @include text-title--large;
      }
    }
    &--content {
      .style--bare {
        @include text-body-text;
      }
    }
  }
  .content-block__links {
    display: block;
    min-height: 1.5em;
    padding-top: 12px;
    @media #{$cr19-large-up} {
      padding-top: 16px;
    }
  }
  &__default {
    @include container-max-width-x-large;
  }

  .moisture-surge-btns {
    display: flex;
    flex-direction: column;
    row-gap: 0.625rem;
    margin-bottom: 0.625rem;
    
    @media #{$cr19-large-up} {
      flex-direction: row;
      justify-content: center;
      column-gap: 1.5rem;
    }
  }
}
